@use '../../../styles/breakpoints.module';

.superhero {
  display: flex;
  max-height: var(--optimised-image-max-height-xsmall);
  min-height: var(--optimised-image-min-height-xsmall);
  position: relative;

  @include breakpoints.for-small-up {
    max-height: var(--optimised-image-max-height-small);
  }
  @include breakpoints.for-medium-up {
    max-height: var(--optimised-image-max-height-medium);
  }
  @include breakpoints.for-large-up {
    max-height: var(--optimised-image-max-height-large);
  }
  @include breakpoints.for-xlarge-up {
    max-height: var(--optimised-image-max-height-xlarge);
  }
  @include breakpoints.for-2xlarge-up {
    max-height: var(--optimised-image-max-height-2xlarge);
  }
  @include breakpoints.for-3xlarge-up {
    max-height: var(--optimised-image-max-height-3xlarge);
  }

  &__image {
    width: 100%;

    img {
      object-fit: cover;
    }

    &--object-position-top {
      img {
        object-position: top;
      }
    }

    &--object-position-center {
      img {
        object-position: center;
      }
    }

    &--object-position-bottom {
      img {
        object-position: bottom;
      }
    }
  }

  &__image--mobile {
    display: block;
    max-height: var(--optimised-image-max-height-xsmall);
    min-height: var(--optimised-image-min-height-xsmall);

    @include breakpoints.for-small-up {
      max-height: var(--optimised-image-max-height-small);
    }

    @include breakpoints.for-medium-up {
      display: none;
    }
  }

  &__image--desktop {
    display: none;

    @include breakpoints.for-medium-up {
      display: block;
      max-height: var(--optimised-image-max-height-medium);
    }
    @include breakpoints.for-large-up {
      max-height: var(--optimised-image-max-height-large);
    }
    @include breakpoints.for-xlarge-up {
      max-height: var(--optimised-image-max-height-xlarge);
    }
    @include breakpoints.for-2xlarge-up {
      max-height: var(--optimised-image-max-height-2xlarge);
    }
    @include breakpoints.for-3xlarge-up {
      max-height: var(--optimised-image-max-height-3xlarge);
    }
  }

  &__background-opacity {
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &__title-copy-cta-block-padding {
    display: flex;
    height: calc(100% - var(--space-8));
    padding: var(--space-4);

    @include breakpoints.for-medium-up {
      height: initial;
    }
  }

  &__title-copy-cta-block {
    display: flex;
    height: 100%;
    position: absolute;
    width: 100%;

    &--max-width-300px {
      max-width: 300px;
    }

    &--max-width-400px {
      max-width: 400px;
    }

    &--max-width-500px {
      max-width: 500px;
    }

    &--max-width-600px {
      max-width: 600px;
    }

    &--max-width-700px {
      max-width: 700px;
    }

    &--max-width-800px {
      max-width: 800px;
    }

    &--max-width-900px {
      max-width: 900px;
    }

    &--max-width-1000px {
      max-width: 1000px;
    }

    &--padding-top-1rem {
      padding-top: var(--space-4);
    }

    &--padding-top-2rem {
      padding-top: var(--space-4);

      @include breakpoints.for-medium-up {
        padding-top: var(--space-8);
      }
    }

    &--padding-top-3rem {
      padding-top: var(--space-4);

      @include breakpoints.for-medium-up {
        padding-top: var(--space-12);
      }
    }

    &--padding-top-4rem {
      padding-top: var(--space-4);

      @include breakpoints.for-medium-up {
        padding-top: var(--space-16);
      }
    }

    &--padding-top-5rem {
      padding-top: var(--space-4);

      @include breakpoints.for-medium-up {
        padding-top: var(--space-20);
      }
    }

    &--padding-bottom-1rem {
      padding-bottom: var(--space-4);
    }

    &--padding-bottom-2rem {
      padding-bottom: var(--space-4);

      @include breakpoints.for-medium-up {
        padding-bottom: var(--space-8);
      }
    }

    &--padding-bottom-3rem {
      padding-bottom: var(--space-4);

      @include breakpoints.for-medium-up {
        padding-bottom: var(--space-12);
      }
    }

    &--padding-bottom-4rem {
      padding-bottom: var(--space-4);

      @include breakpoints.for-medium-up {
        padding-bottom: var(--space-16);
      }
    }

    &--padding-bottom-5rem {
      padding-bottom: var(--space-4);

      @include breakpoints.for-medium-up {
        padding-bottom: var(--space-20);
      }
    }

    &--padding-left-1rem {
      padding-left: var(--space-4);
    }

    &--padding-left-2rem {
      padding-left: var(--space-4);

      @include breakpoints.for-medium-up {
        padding-left: var(--space-8);
      }
    }

    &--padding-left-3rem {
      padding-left: var(--space-4);

      @include breakpoints.for-medium-up {
        padding-left: var(--space-12);
      }
    }

    &--padding-left-4rem {
      padding-left: var(--space-4);

      @include breakpoints.for-medium-up {
        padding-left: var(--space-16);
      }
    }

    &--padding-left-5rem {
      padding-left: var(--space-4);

      @include breakpoints.for-medium-up {
        padding-left: var(--space-20);
      }
    }

    &--padding-right-1rem {
      padding-right: var(--space-4);
    }

    &--padding-right-2rem {
      padding-right: var(--space-4);

      @include breakpoints.for-medium-up {
        padding-right: var(--space-8);
      }
    }

    &--padding-right-3rem {
      padding-right: var(--space-4);

      @include breakpoints.for-medium-up {
        padding-right: var(--space-12);
      }
    }

    &--padding-right-4rem {
      padding-right: var(--space-4);

      @include breakpoints.for-medium-up {
        padding-right: var(--space-16);
      }
    }

    &--padding-right-5rem {
      padding-right: var(--space-4);

      @include breakpoints.for-medium-up {
        padding-right: var(--space-20);
      }
    }
  }
}
