.render-section {
  > div {
    display: flex;
    flex-direction: column;
    gap: var(--space-6);
  }

  &__content-grid {
    align-self: center;
    gap: 0;
    max-width: 932px;
    width: 100%;
  }

  &__accordion:nth-child(1 of &__accordion) {
    margin-top: var(--space-4);
  }

  &__button {
    width: fit-content;
  }
}
