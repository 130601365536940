@use '../../../styles/breakpoints.module';

.text-block {
  display: flex;
  flex-direction: column;

  &__container {
    display: flex;
    flex-direction: column;
    gap: var(--space-2);

    span {
      width: fit-content;
    }
  }

  &--full-bleed {
    align-self: center;
    box-sizing: border-box;
    max-width: var(--util-max-screen-size);
    padding-left: var(--space-6);
    padding-top: var(--space-6);
    width: 100%;
  }
}
