@use '../../../styles/breakpoints.module';

$box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 2%);

.switch-tabs {
	border-radius: 8px;
	box-shadow: $box-shadow;
	display: grid;
	grid-area: 'tabs'
			   'panels';
	padding: var(--space-8) var(--space-6);
	
	&__tabs-wrapper {
		align-self: center;
		justify-self: center;
		margin-bottom: var(--space-8);
		min-width: 310px; 
		
		@include breakpoints.for-small-up {
			min-width: 368px; 
		}

		&__tab-list {
			background-color: var(--alto-sem-color-bg-layer1-default, var(--color-avios-grey-2));
			border-radius: var(--alto-sem-radius-circle, 9999px);
			display: flex;
			margin: 0;
			padding: var(--space-1);

			li {
				flex: 1;
				list-style-type: none;
			}

			&__tab-button {
				align-items: center;
				background-color: var(--alto-sem-color-bg-layer1-default, var(--color-avios-grey-2));
				border: 0;
				border-radius: var(--alto-sem-radius-circle, 9999px);
				display: flex;
				font-size: var(--text-sm);
				font-weight: var(--font-weight-regular);
				height: 100%;
				justify-content: center;
				padding: var(--space-2);
				white-space:nowrap;
				width: 100%;

				&:hover {
					cursor: pointer;
				}

				&--active {
					background-color: #E4E9FF;
					font-weight: var(--font-weight-semi-bold);
				}
			}
		}
	}
}
  