.accordion {
  $block: &;

  border-bottom: var(--component-accordion-border);

  &:nth-child(1 of #{$block}) {
    border-top: var(--component-accordion-border);
  }

  &__chevron {
    font-size: var(--text-2xl);
    margin-left: var(--space-4);
    transition: transform var(--component-accordion-animation-speed);

    #{$block}--open & {
      transform: rotate(180deg);
    }
  }

  &__heading {
    font-family: var(--component-accordion-heading-font);
    margin: 0;
  }

  &__title {
    align-items: center;
    color: var(--component-accordion-title-color);
    cursor: pointer;
    display: flex;
    font-size: var(--text-xl);
    justify-content: space-between;
    line-height: 1.15;
    padding: var(--space-3);
    padding-left: 0px;
    text-align: left;
    width: 100%;
    word-break: break-all;
  }

  &__body {
    #{$block}--open & {
      margin: var(--space-6) 0;
    }

    box-sizing: border-box;
    overflow: hidden;
    transition: all var(--component-accordion-animation-speed);
  }
}
