@use '../../../styles/breakpoints.module';

.article-story {
  display: grid;
  gap: var(--space-6);
  grid-template-areas:
    'media'
    'content';
  @include breakpoints.for-large-up {
    grid-template-areas: 'media content';
    grid-template-columns: 3fr 9fr;
  }

  &__youtube {
    aspect-ratio: 16 / 9;
    grid-area: media;
    width: 100%;
  }

  &__image {
    aspect-ratio: 14 / 11;
    grid-area: media;
    min-width: 336px;
    width: 100%;

    @include breakpoints.for-medium-up {
      aspect-ratio: 2 / 1;
    }
    @include breakpoints.for-large-up {
      aspect-ratio: 43 / 29;
    }
    @include breakpoints.for-xlarge-up {
      aspect-ratio: 2 / 1;
    }

    img {
      border-radius: var(--space-2);
      object-fit: cover;
      width: 100%;
    }
  }

  &__content {
    grid-area: content;
  }

  &__title {
    margin: 0 0 var(--space-4);
  }

  &__body {
    color: var(--color-neutrals-grey-1);
    margin: 0 0 var(--space-6);
    text-align: justify;
  }

  &__cta {
    padding: 0;
  }
}
