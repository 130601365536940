@use '../../styles/breakpoints.module';

.mgm {
  border-radius: var(--util-border-radius);
  box-shadow: var(--util-shadow);
  overflow: hidden;

  &__banner {
    background-image: var(--module-mgm-banner-background);
    color: var(--color-neutrals-white);
    display: grid;
    grid-gap: var(--space-6);
    grid-template-columns: 100%;
    padding: var(--space-12) var(--space-4);
    position: relative;

    &-title {
      color: var(--color-neutrals-white);
    }

    &-cta {
      background-color: var(--color-neutrals-white);
      color: var(--color-blues-primary);

      &:hover {
        background: var(--module-mgm-toggle-btn-background);
        color: var(--module-mgm-cta-color);
        filter: none;
      }
    }

    img {
      object-fit: fill;
      object-position: top;
      position: relative !important; // Needed to override NextImage inline style when height is unknown
    }

    @include breakpoints.for-medium-up {
      img {
        position: absolute !important;
      }
    }

    @include breakpoints.for-medium-up {
      grid-template-columns: 4fr 7fr;
      padding: var(--space-6) var(--space-6) var(--space-8) var(--space-6);
      transition: padding-bottom 0.2s;

      &--open {
        padding-bottom: var(--space-12);
      }

      &-image {
        height: 100%;
      }

      img {
        object-fit: contain;
        object-position: right top;
      }
    }

    @include breakpoints.for-large-up {
      padding: var(--space-6) calc(var(--space-20) + var(--space-12))
        var(--space-6) calc(var(--space-20) + var(--space-12));

      &--open {
        padding-bottom: var(--space-12);
      }
    }
  }

  &__close-btn {
    color: var(--color-neutrals-white);
    position: absolute;
    right: 0;

    &:hover {
      background-color: transparent;
      opacity: 0.5;
    }
  }

  &__accordion {
    max-height: 0;
    position: relative;
    transition: max-height 0.2s;

    &--open {
      max-height: 1000px;
    }
  }

  &__terms {
    font-size: var(--text-sm);
    padding: 0;
  }

  &__terms-extra-content {
    padding-top: var(--space-2);
  }
}

.offers {
  background-color: var(--color-neutrals-grey-7);
  padding: var(--space-6) var(--space-4);

  @include breakpoints.for-medium-up {
    padding: var(--space-6);
  }

  @include breakpoints.for-large-up {
    padding: var(--space-6) calc(var(--space-20) + var(--space-12));
  }

  &__wrapper {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: calc(var(--space-2) * -1);
    transition: margin-top 0.2s;

    @include breakpoints.for-medium-up {
      align-items: initial;
      flex-direction: row;
      justify-content: center;
      margin-top: calc(var(--space-5) * -1);
    }

    &--open {
      margin-top: calc(#{var(--space-10)} * -1);
    }
  }

  &__card {
    background-color: var(--color-neutrals-white);
    border-radius: var(--util-border-radius);
    box-shadow: var(--util-shadow);
    overflow: hidden;
    width: 100%;

    &:first-child {
      margin-bottom: var(--space-6);
      margin-right: 0;
    }

    @include breakpoints.for-medium-up {
      width: 50%;

      &:first-child {
        margin-bottom: 0;
        margin-right: var(--space-6);
      }
    }
  }

  &__title {
    background-image: var(--module-mgm-offers-title-background);
    padding: var(--space-4) calc(#{var(--space-1)} + #{var(--space-2)});
  }

  &__description {
    color: var(--color-neutrals-grey-1);
  }

  &__amount {
    font-size: 20px;

    span {
      font-size: 32px;
    }
  }

  &__content {
    padding: var(--space-4);
  }
}

.share {
  background-color: var(--color-neutrals-white);
  padding: var(--space-4);

  &__copy-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: var(--space-6);
    position: relative;

    input {
      margin-bottom: var(--space-6);
    }

    @include breakpoints.for-large-up {
      flex-direction: row;

      input {
        box-sizing: border-box;
        margin-bottom: 0;
        margin-right: var(--space-6);
        width: 50%;
      }
    }
  }

  &__dialog-wrapper {
    display: flex;
    position: relative;

    @include breakpoints.for-large-up {
      position: unset;
      width: 50%;
    }
  }

  &__copy-btn-wrapper {
    position: unset;
    width: 50%;

    @include breakpoints.for-large-up {
      position: relative;
      width: 50%;
    }
  }

  &__dialog {
    animation: show-dialog 1s;
    background-color: var(--color-blues-background);
    border-radius: var(--util-small-border-radius);
    bottom: calc(100% + #{var(--space-4)});
    box-sizing: border-box;
    left: 0;
    overflow: hidden;
    padding: var(--space-4);
    right: auto;
    transform: none;
    width: auto;

    @include breakpoints.for-large-up {
      left: 50%;
      transform: translateX(-50%);
      width: max-content;
    }
  }

  &__copy {
    padding: 16px 8px;
    white-space: pre;
    width: 100%;
  }

  &__whatsapp {
    background-color: var(--module-mgm-whatsapp-btn-background);
    margin-left: var(--space-5);
    padding: 16px 8px;
    width: 50%;

    &:hover {
      background-color: var(--module-mgm-whatsapp-btn-background);
    }

    @include breakpoints.for-medium-up {
      margin-left: var(--space-6);
    }
  }

  @include breakpoints.for-medium-up {
    padding: var(--space-6);
  }

  @include breakpoints.for-large-up {
    padding: var(--space-6) calc(var(--space-20) + var(--space-12));
  }
}

@keyframes show-dialog {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
