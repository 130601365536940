/* stylelint-disable declaration-block-no-redundant-longhand-properties */

@use '../../../styles/breakpoints.module';

.title-copy-cta-block-container {
  display: flex;
  flex-direction: column;
  gap: var(--space-4);
}

.title-copy-cta-block-full-bleed {
  align-self: center;
  box-sizing: border-box;
  max-width: var(--util-max-screen-size);
  padding-left: var(--space-6);
  padding-top: var(--space-6);
  width: 100%;
}

.title-copy-cta-block {
  display: flex;
  flex-direction: column;
  gap: var(--space-6);
  justify-content: space-between;

  @include breakpoints.for-xlarge-up {
    &--inline {
      flex-direction: row;
      gap: var(--space-4);
      justify-content: space-between;
    }
  }

  &__headings-container {
    flex: 1;
  }

  &__headings {
    display: flex;
    flex-direction: column;
    gap: var(--space-2);
    justify-content: space-between;
  }

  &__ctas {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-4);
  }

  &__links {
    display: flex;
    flex-direction: column;

    a {
      width: fit-content;
    }
  }

  &__primary-link {
    padding: 0;
  }

  &__secondary-link {
    margin-top: var(--space-4);
  }

  h1,
  h2,
  h3,
  h4,
  p,
  ul {
    margin-bottom: 0;
  }

  &__title {
    font-size: var(--text-6xl);
  }

  &__description {
    display: flex;
    flex-direction: column;
    padding-top: var(--space-4);

    a {
      color: var(--component-title-copy-cta-block-link-color);
      text-decoration: underline;
    }

    @include breakpoints.for-medium-up {
      margin: 0;
    }

    &--text-align-left {
      > p {
        text-align: left;
      }
    }

    &--text-align-center {
      > p {
        text-align: center;
      }
    }

    &--text-align-right {
      > p {
        text-align: right;
      }
    }
  }

  &__primary-cta,
  &__secondary-cta {
    width: fit-content;
  }

  &__secondary-cta {
    background: var(--component-title-copy-cta-block-cta-bgcolor);
  }
}
