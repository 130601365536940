@use '../../../styles/breakpoints.module';

$border-radius: var(--space-3);
$box-box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 5%);
$padding-text: 70px 80px;
$icon-background-size: 42px;
$icon-card-small-width: 260px;
$icon-card-xlarge-width: 300px;

.text-wrapper {
  border-radius: $border-radius;
  display: flex;
  flex-direction: column;
  gap: var(--space-6);
  justify-content: space-between;
  padding: var(--space-4);
  z-index: 2;

  &--align-left {
    align-self: flex-start;
  }

  &--align-center {
    align-self: center;
  }

  &--align-right {
    align-self: flex-end;
  }

  p {
    margin: 0;
  }

  &--white {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: var(--color-neutrals-white);
    }
  }

  &--black {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      color: var(--color-neutrals-black);
    }
  }

  &--primary {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--component-card-image-primary-color);
    }

    p {
      color: var(--component-card-image-primary-color-description);
    }
  }

  &__buttons-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-4);
  }

  &__pill {
    background: var(--component-card-image-pill-background-color);
    border-radius: 16px;
    color: var(--component-card-image-pill-color);
    font-size: var(--text-sm);
    font-weight: var(--font-weight-regular);
    padding: var(--space-1) var(--space-4);
    width: fit-content;

    &__background {
      background: var(--component-card-image-pill-background-color);
      border-radius: 16px;
    }
  }
}

.card {
  border-radius: $border-radius;
  display: grid;

  &--white {
    background-color: var(--color-neutrals-white);
  }

  &--accent-secondary {
    background-color: var(--color-avios-blue-1);
  }

  &__promotional-card-image-right {
    box-shadow: $box-box-shadow;
    grid-template-columns: 65% 35%;
    min-height: 240px;

    &__image-wrapper {
      &--image-right {
        margin-left: -10px;

        img {
          border-bottom-right-radius: $border-radius;
          border-top-right-radius: $border-radius;
          object-fit: cover;
          object-position: center;
        }
      }
    }
  }

  &__card-cta-image-right,
  &__card-cta-image-left {
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 3fr;
    width: 100%;

    &__text-wrapper {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      grid-row: 2/3;
      margin-top: -10px;
      padding: 32px 16px;
      z-index: 1;
    }

    &__image-wrapper {
      grid-row: 1/2;
      z-index: 2;

      img {
        border-radius: $border-radius;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &__card-cta-image-right {
    @include breakpoints.for-medium-up {
      grid-template-columns: 65% 35%;
      grid-template-rows: 1fr;

      &__text-wrapper {
        border-bottom-right-radius: 0;
        border-top-left-radius: $border-radius;
        border-top-right-radius: 0;
        grid-column: 1/2;
        grid-row: 1;
        margin-right: -10px;
        margin-top: 0;
        padding: $padding-text;
        z-index: 1;
      }

      &__image-wrapper {
        grid-column: 2/3;
        grid-row: 1;
      }
    }
  }

  &__card-cta-image-left {
    @include breakpoints.for-medium-up {
      grid-template-columns: 35% 65%;
      grid-template-rows: 1fr;

      &__text-wrapper {
        border-radius: 0 $border-radius $border-radius 0;
        grid-column: 2/3;
        grid-row: 1;
        margin-left: -10px;
        margin-top: 0;
        padding: $padding-text;
        z-index: 1;
      }

      &__image-wrapper {
        grid-column: 1/2;
        grid-row: 1;
      }
    }
  }

  &__icon-simple {
    align-items: center;
    border: var(--component-card-icon-simple-border);
    border-radius: $border-radius;
    box-shadow: $box-box-shadow;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    padding: var(--space-4) var(--space-3);
    width: $icon-card-small-width;

    &:hover {
      background: var(--component-card-icon-simple-hover-background-color);
    }

    &:focus-visible {
      background: var(--component-card-icon-simple-hover-background-color);
      outline: var(--component-card-icon-simple-focus-visible-outline);
      outline-offset: 2px;
    }

    &:active {
      background: var(--component-card-icon-simple-pressed-background-color);
    }

    @include breakpoints.for-xlarge-up {
      width: $icon-card-xlarge-width;
    }
  }

  &__icon {
    align-items: center;
    color: var(--base-link-color);
    display: flex;
    font-size: var(--text-2xl);
    height: $icon-background-size;
    justify-content: center;
    width: $icon-background-size;
  }

  &__text-block {
    padding: 0;
    padding-left: var(--space-4);
  }

  &__partner-inline-card {
    background-color: var(--color-neutrals-white);
    box-shadow: $box-box-shadow;
    grid-template-columns: 1fr;
    grid-template-rows: 150px 126px;
    position: relative;
    width: 100%;

    &__image-container {
      position: relative;
      width: 100%;
    }

    &__image-wrapper {
      grid-row: 1/2;
      height: 150px;

      img {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        object-fit: cover;
        object-position: center;
      }
    }

    &__logo {
      background-color: var(--color-neutrals-white);
      bottom: 26px;
      left: 16px;
      position: absolute;
      z-index: 2;
    }

    &__description {
      border-bottom-left-radius: $border-radius;
      border-bottom-right-radius: $border-radius;
      display: flex;
      flex-direction: column;
      margin-top: -10px;
      padding: var(--space-7) var(--space-4);
      z-index: 2;

      &__partner-wrapper {
        align-items: center;
        display: grid;
        grid-template-columns: 1fr 1fr;
      }

      &__partner-name {
        font-weight: var(--font-weight-bold);
        margin-bottom: 8px;
      }

      &__partner-rate {
        align-items: center;
        background: var(--color-avios-grey-2);
        border-radius: 8px;
        display: flex;
        font-size: var(--text-sm);
        font-weight: var(--font-weight-medium);
        justify-content: center;
        margin: 0;
        padding: var(--space-2) var(--space-3);
        text-align: center;

        &__icon {
          font-size: var(--text-xl);
          margin-right: var(--space-1);
        }

        &--background-green {
          background: var(--color-avios-green-7);
        }

        @include breakpoints.for-small-up {
          font-size: var(--text-lg);
        }
      }

      &__partner-was-rate {
        color: var(--color-avios-grey-1);
        font-size: var(--text-sm);
        text-align: center;

        @include breakpoints.for-small-up {
          font-size: var(--text-lg);
        }
      }
    }
  }
}
