@use '../../../styles/breakpoints.module';

.content {
  display: flex;
  flex-direction: column;
  gap: var(--space-6);

  &__carousel {
    &--item {
      > * {
        height: 100%;
      }
    }
  }

  &__grid {
    display: flex;
    flex-direction: column;

    &--align-left {
      justify-items: flex-start; // for display: grid
    }

    &--align-center {
      align-items: center;
      justify-items: center;
    }

    &--align-right {
      align-items: flex-end;
      justify-items: flex-end;
    }

    &--content-separators {
      > div {
        border-bottom: 1px solid;
        border-color: #ccc;
        padding-bottom: var(--space-6);
      }

      > div:last-child {
        border-bottom: none;
      }
    }

    @include breakpoints.for-medium-up {
      display: grid;
      gap: var(--space-6);
      grid-template-columns: 100%;
      grid-template-rows: 1fr;
    }

    &--2-item {
      @include breakpoints.for-large-up {
        grid-template-columns: 1fr 1fr;
      }
    }

    &--3-item {
      @include breakpoints.for-medium-up {
        grid-template: 'first second third' / 1fr 1fr 1fr;
      }

      div:nth-child(1) {
        grid-area: first;
      }

      div:nth-child(2) {
        grid-area: second;
      }

      div:nth-child(3) {
        grid-area: third;
      }
    }

    &--4-item {
      @include breakpoints.for-medium-up {
        grid-template-areas:
          'first second'
          'third fourth';
        grid-template-columns: 1fr 1fr;
      }
      @include breakpoints.for-xlarge-up {
        grid-template-areas: 'first second third fourth';
        grid-template-columns: 1fr 1fr 1fr 1fr;
      }

      div:nth-child(1) {
        grid-area: first;
      }

      div:nth-child(2) {
        grid-area: second;
      }

      div:nth-child(3) {
        grid-area: third;
      }

      div:nth-child(4) {
        grid-area: fourth;
      }
    }
  }

  &__row-overflow {
    flex-direction: row;
    overflow-x: auto;

    a:first-child {
      margin-left: auto;
    }

    a:last-child {
      margin-right: auto;
    }
  }
}
