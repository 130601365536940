$border: 1px solid var(--alto-sem-color-border-tertiary, rgba(149, 147, 160, 25%));
$shadow: 0px 1px 2px 0px rgba(0, 0, 0, 5%);

.carousel {
	&__loader {
		display: flex;
		height: 2em;
	  }

	&__tab {
		overflow: hidden;
	}

	&__empty-list {
		align-items: center;
		background: var(--color-neutrals-white-2);
		border: $border;
		border-radius: var(--space-3);
		box-shadow: $shadow;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin: 0 auto;
		max-width: var(--util-carousel-container-max-width);
		padding: var(--space-8) var(--space-6);


		p {
			color: var(--color-neutrals-black);
			font-size: var(--text-base);
			font-weight: var(--font-weight-regular);
			margin: 0;
		}
	}

	&__see-all {
		align-items: center;
		display: flex;
		flex-direction: column;
		height: 110px;
		justify-content: space-between;

		&__button {
			background-color: var(--color-neutrals-white-2);
			border: $border;
			border-radius: 50%;
			box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 5%);
			color: var(--color-neutrals-black);
		}

		&__text {
			color: var(--color-neutrals-black);
			font-size: var(--text-base);
			font-weight: var(--font-weight-regular);
			margin: 0;
		}
	}

	&__image-card {
		align-items: center;
		box-shadow: $shadow;
		display: flex;
		flex-direction: column;
		gap: var(--space-2);

		&__logo-wrapper {
			align-items: center;
			background: var(--color-neutrals-white-2);
			border: $border;
			border-radius: var(--space-3);
			display: flex;
			justify-content: center;
			padding: var(--space-2);
			position: relative;
			width: 168px;

			&__logo {
				height: 60px;
				width: 100%;

				img {
					object-fit: contain;
					width: 100%;
				}
			}
		}

		&__name {
			color: var(--color-neutrals-black);
			font-size: var(--text-base);
			font-weight: var(--font-weight-regular);
			margin: 0;
			text-align: center;
		}
	}
}