@use '../../../styles/breakpoints.module';

.container {
  display: flex;
  overflow: hidden;

  &-flex-start {
    justify-content: flex-start;
  }

  &-center {
    justify-content: center;
  }

  &-flex-end {
    justify-content: flex-end;
  }

  &-column {
    display: flex;
    flex-direction: column;
    gap: var(--space-4);
    width: 100%;
  }

  &-row {
    display: grid;
    gap: var(--space-4);
    grid-auto-flow: column;
    max-width: calc(var(--util-max-screen-size));
    overflow: scroll;
    -ms-overflow-style: none;
    padding: calc(var(--space-5) / 2) var(--space-4) var(--space-2);
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &--1-row {
    grid-template-rows: repeat(1, auto);

    @include breakpoints.for-xlarge-up {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
    }
  }

  &--2-row {
    grid-template-rows: repeat(2, auto);
  }

  &--3-row {
    grid-template-rows: repeat(3, auto);
  }

  &--4-row {
    grid-template-rows: repeat(4, auto);
  }
}
